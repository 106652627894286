import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import Helmet from "react-helmet"
import {
  MoonIcon,
  SunIcon,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
} from "./icons"

const Layout = ({ location, title, children, shareUrl }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  let header

  if (isRootPath) {
    header = (
      <h1 className="main-heading">
        <Link to="/">{title}</Link>
      </h1>
    )
  } else {
    header = (
      <Link className="header-link-home" to="/">
        {title}
      </Link>
    )
  }
  const [theme, setTheme] = useState("dark")
  useEffect(() => {
    const loadTheme = () => {
      const theme = localStorage.getItem("ad_theme")
      return theme || "dark"
    }
    setTheme(loadTheme())
  }, [])
  useEffect(() => {
    localStorage.setItem("ad_theme", theme)
  }, [theme])
  const toggleTheme = () => {
    const themes = ["light", "dark"]
    const index = themes.indexOf(theme)
    const targetTheme = Math.abs(index - 1)
    setTheme(themes[targetTheme])
  }
  const fbLink = shareUrl && shareUrl.fb ? shareUrl.fb : '#'
  const twLink = shareUrl && shareUrl.tw ? shareUrl.tw : '#'
  const lkLink = shareUrl && shareUrl.lk ? shareUrl.lk : '#'
  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      <Helmet
        bodyAttributes={{
          "data-theme": theme,
        }}
      />
      <header className="global-header">{header}</header>
      <main>{children}</main>
      <footer>
        © {new Date().getFullYear()}, Dibuat menggunakan
        {` `}
        <a href="https://www.gatsbyjs.com">Gatsby</a>
      </footer>
      {shareUrl && (
        <div className="share-btn-wrap">
          <a
            target="_blank"
            href={fbLink}
            rel="noreferrer"
            className="bottom-fixed share share-facebook"
          >
            <FacebookIcon />
          </a>
          <a
            target="_blank"
            href={twLink}
            rel="noreferrer"
            className="bottom-fixed share share-twitter"
          >
            <TwitterIcon />
          </a>
          <a
            target="_blank"
            href={lkLink}
            rel="noreferrer"
            className="bottom-fixed share share-linkedin"
          >
            <LinkedinIcon />
          </a>
        </div>
      )}
      <button
        className={`bottom-fixed theme-toggle ${theme}`}
        onClick={toggleTheme}
      >
        {theme === "dark" && <SunIcon />}
        {theme === "light" && <MoonIcon />}
      </button>
    </div>
  )
}

export default Layout
